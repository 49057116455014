import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const generateAlipayIframeUrl = async (itemId) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/alipay/${itemId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getAlipayIframeUrl= async (outId) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/alipay/orders/${outId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}